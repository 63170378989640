import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Deleted = () => {
  const navigate = useNavigate();

  return (
    <section style={{ textAlign: "start" }} id="cancellationpolicy">
      <Container>
        <h1 className="heading my-5" style={{ textAlign: "center" }}>
          Deleted
        </h1>
        <p className="para">
          Your account has been successfully deleted.
          <br /> We appreciate your use of our services and wish you the best in
          your future endeavors. Should you decide to return, we welcome you to
          create a new account and continue enjoying our offerings. Thank you
          for choosing Nuclear Engineering Inspection.
        </p>

        <div className="submit-div d-flex justify-content-center mt-5">
          <button
            onClick={() => navigate("/", { replace: true })}
            type="submit"
            className="btn submit-btn"
          >
            Back to Home Page
          </button>
        </div>
      </Container>
    </section>
  );
};
export default Deleted;
